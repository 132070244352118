export interface Account {
  id: number;
  email: string;
  fullname: string;
  isActive: boolean;
}

export interface Permission {
  id: number;
  name: string;
  key: PermissionName;
}

export interface Access {
  token: string;
}

export interface Refresh {}

export interface AuthResponse {
  account: Account;
  permissions: Permission[];
  access: Access;
  refresh: Refresh;
}

export enum PermissionName {
  SECTIONS_PACKAGES_ALL = 'sections-packages.all',
  SECTION_PACKAGES_LOAD_ALL = 'section-packages-load.all',
  ENROLLMENT_MODIFICATION = 'enrollment-modification.all',
  SEARCH_TEACHER = 'search.teacher',
  TEACHERS_ACTIVATION_ALL = 'teachers-activation.all',
  ACTIVATE_TEACHER_ACCOUNT = 'activate.teacher',
  SECTIONS_MODIFICATION = 'modification-sections.all',
  STUDENTS_POST_CLOSE = 'admin-portal.students-post-close',
  // PERMISOS DE ESTRUCTURA ACACADEMICA
  ACADEMIC_STRUCTURE = 'academic-structure.all',
  // PERMISOS DE OFERTA ACADEMICA
  ACADEMIC_OFFER_CURRICULAR_SUBJECT = 'academic-offer.curricular-subject.all',
  ACADEMIC_OFFER_CURRICULUM = 'academic-offer.curriculum.all',
  ACADEMIC_OFFER_STUDY_PLAN = 'academic-offer.study-plans.all',
  ACADEMIC_OFFER_VACANT_OFFER = 'academic-offer.vacant-offer.all',
  ACADEMIC_OFFER_INTERIM_DEGREE = 'academic-offer.interim-degree.all',
  // PERMISOS DE ADMISION Y MATRICULA
  ADMISSIONS_AND_ENROLLMENTS_TARIFFS_AND_DISCOUNTS = 'admissions-and-enrollments.tariffs-and-discounts.all',
  ADMISSIONS_AND_ENROLLMENTS_TUITION_PROCESS = 'admissions-and-enrollments.tuition-process.all',
  ADMISSIONS_AND_ENROLLMENTS_TUITION_PROCESS_CHANGE_STATUS = 'admissions-and-enrollments.tuition-process.change-status',
  ADMISSIONS_AND_ENROLLMENTS_ADMISSION_MANAGEMENT = 'admissions-and-enrollments.admission-management.all',
  ADMISSIONS_AND_ENROLLMENTS_DOCUMENTATION_REVIEW_PROCESS = 'admissions-and-enrollments.documentation-review-process.all',
  // PERMISOS DE HERRAMIENTAS DE  GESTION
  MANAGEMENT_TOOLS_USERS_AND_ROLES = 'management-tools.users-and-roles.all',
  // PERMISOS DE GRADUACIONES DE TITULOS INTERMEDIOS
  INTERIM_DEGREE_GRADUATION_PROCESS_ALL = 'interim-degree-graduation-process.all',
  // PERMISOS DE MATRÍCULA DE CONTINUIDAD
  TUITION_CONTINUITY_TARIFF_AND_TUITION = 'tuition-continuity.tariff-and-tuition.all',
  TUITION_CONTINUITY_MANAGEMENT = 'tuition-continuity.management.all',
  TUITION_CONTINUITY_DOCUMENT_REVIEW = 'tuition-continuity.document-review.all',
  // PERMISOS DE GESTIÓN DEL ESTUDIDANTE
  STUDENT_MANAGEMENT_STUDENTS_DEGREE = 'student-management.students-degree.all',
  // MÓDULOS DE SECCIONES
  SUBJECT_SECTIONS_PERIOD_CLOSE = 'subject-sections.period-close.list',
  SUBJECT_SECTIONS_PERIOD_CLOSE_CHANGE_STATUS = 'subject-sections.period-close.manage',
  // HERRAMIENTAS DE GESTION
  REPORTS = 'reports.all',
  REPORTS_STUDENTS_WITHOUT_EMAIL = 'reports.students-without-email',
  //GESTION DEL ESTUDIANTE
  STUDENT_MANAGEMENT_STUDENT_FILE = 'student-management.student-file.all',
  STUDENT_CURRICULUM_PROGRESS_LIST = 'studen-management.curriculum-progress.all',
  STUDENT_MANAGEMENT_ENROLLMENT_SOFT_CHANGE = 'student-management.enrollment-soft-change.all',
  STUDENT_MANAGEMENT_PETITIONS = 'student-management.petitions.all',
  STUDENT_MANAGEMENT_STUDENT_STATUS = 'student-management.student-status.list',
  STUDENT_MANAGEMENT_STUDENT_STATUS_MANAGE = 'student-management.student-status.manage',
  STUDENT_MANAGEMENT_GRADUATION_PROCESS = 'student-management.graduation-process.all',
  //CONVALIDACIÓN INTERNA
  COURSE_VALIDATION = 'course-validation.all',
  //CARGA INICIAL
  ACADEMIC_RECORD = 'academic-record.all',
  //CONFIGURACION GENERAL
  SETTINGS = 'settings.all',
  //CENTRO DE PAGOS
  PAYMENT_HUB = 'payment-hub.all',
}

export enum UserSessionEvent {
  EXPIRED = 'user-session-expired',
}
